


.carousel {
  position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width:95%;
    min-height: 60%; 
    cursor: zoom-in;
    
  }
  .carouselZoom {
    position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
     
      min-height: 60%; 
    
      
    }

  @media only screen and (max-width: 600px) {
    .carousel {
      position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width:100%;
        height: 60%; 
        margin-bottom: 10px;
        cursor: zoom-in;
      }

      
  }
  
  @media only screen and (min-width: 1000px) {
    .carouselhover{
      width:80%;
      position: relative;
      display: flex;
      
      z-index: 1500;
      justify-content: center;
      align-items: center;
      
      height: 50vh;
      transform: scale(1.8);
      margin: auto;
      margin-left: 20vw;
      
      transition: 200ms transform ease-in-out;
      cursor: zoom-out;
    }
    
    
  }
  .slides{
    justify-content: center;
  }
  
  .slide {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    width: 100%;
    object-fit: contain;
    display: flex; 
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .slideBlur {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    width: 100%;
    height: 90%;
    display: flex; 
    filter: blur(5px);
  }
  .slide-hidden {
    display: none;
  }
  
  .arrow {
    position: absolute;
    filter: drop-shadow(0px 0px 5px #555);
    background-color:  black;
    width: 1rem;
    height: 1rem;
    color: white;
  }
  
  .arrow:hover {
    cursor: pointer;
    color: orange;
  }
  
  .arrow-left {
    left: 1rem;
  }
  
  .arrow-right {
    right: 1rem;
  }
  
  .indicators {
 
    display:flex;
  }
  
  .indicator {
    background-color: white; 
    max-width: 15%;
    height: 5.35rem;
    border: none;
    outline: none;
    margin: 5px;
    margin-left: 0%;
    margin-bottom: 5%;
    cursor: pointer;
    border: 1px solid blue;
  }

  .indicator-arrow-indicator-arrow-left{
    height: 5.35rem;
    width:min-content;   
    margin: 5px;
    margin-left: 0%;
    background-color: black;
    color: white;
    margin-bottom: 0%;
    align-content: center;
    cursor: pointer;
    border-bottom-left-radius: 5px;
  }
  .indicator-arrow-indicator-arrow-right{
    height: 5.35rem;
    width:min-content;
    margin: 5px;
    margin-left: 0%;
    background-color: black;
    color: white;
    margin-bottom: 0%;
    align-content: center;
    cursor: pointer;
    border-bottom-right-radius: 5px;

  }


  .indicator-arrow-indicator-arrow-leftt{
    height: 4.2rem;
    width:min-content;   
   
    margin-left: 0%;
    background-color: black;
    color: white;
    margin-bottom: 0%;
    align-content: center;
    cursor: pointer;
    border-bottom-left-radius: 5px;
  }
  .indicator-arrow-indicator-arrow-rightt{
    height: 4.2rem;
    width:min-content;
   
    margin-left: 0%;
    background-color: black;
    color: white;
    margin-bottom: 0%;
    align-content: center;
    cursor: pointer;
    border-bottom-right-radius: 5px;

  }





  .indicatorZoom{
    height: 4rem;
    width:6rem;

  }
 
  
  @media only screen and (max-width: 750px) {
    .indicator {
      height: 3rem;
      width:min-content;
     
      display: none;
    }
    .indicator-arrow-indicator-arrow-left{
     display: none;
    }
    .indicator-arrow-indicator-arrow-right{
      display: none;
    }
  }

  @media only screen and (max-width: 1200px) {
    .indicator {
      
      max-width:13.6%;
     
    }
  }
  
  .indicator-inactive {

     border:none;
  }

  .Exclusive{
    animation: zoom 1s infinite;
 
  }
  @keyframes zoom {
    0%, 100% {
        transform: scale(1.03);
    }
    50% {
        transform: scale(1.06);
    }
}


